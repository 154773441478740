import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed
import ProfileForm from '../components/ProfileForm';
import ProfilePictureUpload from '../components/ProfilePictureUpload';
import { Link } from 'react-router-dom'; // Import Link for navigation

const UserSettingsPage = () => {
    const { currentUser } = useContext(AuthContext);

    if (!currentUser) {
        return <p>Please log in.</p>; // or redirect to login page
    }

    return (
        <div className="settings-container">
            <h1 className="settings-header">User Settings</h1>
            <ProfileForm />
            <ProfilePictureUpload />
            <Link to="/change-password" className="change-password-link">Change Password</Link>
        </div>
    );
};

export default UserSettingsPage;


