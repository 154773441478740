import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import ChangePasswordForm from '../components/ChangePasswordForm';
import PasswordChangedBanner from '../components/PasswordChangedBanner'; // Assuming this is the correct import

const ChangePasswordPage = () => {
    const { currentUser } = useContext(AuthContext);
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);

    if (!currentUser) {
        return <p>Please log in.</p>;
    }

    const handlePasswordChangeSuccess = () => {
        setShowSuccessBanner(true);
        // Optionally set a timeout to hide the banner after some time or navigate away
        setTimeout(() => setShowSuccessBanner(false), 2000); // Hide after 2 seconds
    };

    return (
        <div className="change-password-container">
            <h1 className="change-password-header">Change Password</h1>
            <ChangePasswordForm onSuccess={handlePasswordChangeSuccess} />
            {showSuccessBanner && <PasswordChangedBanner onClose={() => setShowSuccessBanner(false)} />}
        </div>
    );
};

export default ChangePasswordPage;
