import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase'; // Your Firebase auth import

const ReauthenticationForm = ({ onSuccess }) => {
    const [email, setEmail] = useState(auth.currentUser ? auth.currentUser.email : '');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const reauthenticate = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            onSuccess(); // Call the onSuccess callback after successful re-authentication
        } catch (error) {
            setError('Failed to re-authenticate. Please check your credentials and try again.');
        }
    };

    useEffect(() => {
        if (auth.currentUser) {
            setEmail(auth.currentUser.email);
        }
    }, []);

    return (
        <div className="reauthentication-container">
            {error && <p className="form-error">{error}</p>}
            <form onSubmit={reauthenticate} className="reauthentication-form">
                <div className="form-group">
                    <label className="form-label">Email:</label>
                    <input type="email" className="form-input" value={email} readOnly required />
                </div>
                <div className="form-group">
                    <label className="form-label">Password:</label>
                    <input type="password" className="form-input" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <button className="form-button" type="submit">Sign in</button>
            </form>
        </div>
    );
};

export default ReauthenticationForm;
