import React from 'react';
import { Link } from 'react-router-dom';

const ForgotPasswordLink = () => {
    return (
        <p>
            <Link to="/reset-password">Forgot Password?</Link>
        </p>
    );
};

export default ForgotPasswordLink;
