import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoginForm from '../components/LoginForm';
import ForgotPasswordLink from '../components/ForgotPasswordLink';
import RedirectLinktoRegister from '../components/RedirectLinkToRegister';

const LoginPage = () => {
    const { currentUser, isLoading } = useContext(AuthContext); // Add isLoading

    if (isLoading) {
        return <div>Loading...</div>; // Show loading while authentication state is being determined
    }

    if (currentUser) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div className="login-page">
            <h1>Login</h1>
            <LoginForm />
            <ForgotPasswordLink />
            <RedirectLinktoRegister />
        </div>
    );
};

export default LoginPage;
