import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { AuthContext } from '../../context/AuthContext';

const NavigationBar = () => {
    const navigate = useNavigate();
    const { currentUser, userRole } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
        setIsOpen(false);
    };

    const toggleMenu = () => setIsOpen(!isOpen);

    const NavLink = ({ to, children }) => (
        <div onClick={() => {
            setIsOpen(false);
            navigate(to);
        }}>
            {children}
        </div>
    );

    // Navbar for logged-out users
    if (!currentUser) {
        return (
            <nav>
                <div className="hamburger" onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={`nav-left ${isOpen ? 'open' : ''}`}>
                    <NavLink to="/enter-invitation">Enter Code</NavLink>
                    <NavLink to="/redirect-to-elvr-labs">Join Waitlist</NavLink>
                </div>
                <div className="nav-right">
                    <button className="btn logout-btn" onClick={() => navigate('/login')}>Log in</button>
                </div>
            </nav>
        );
    }

    // Navbar for partial users
    if (userRole === 'partialuser') {
        return (
            <nav>
                <div className="hamburger" onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={`nav-left ${isOpen ? 'open' : ''}`}>
                    <NavLink to="/mini-dashboard">Dashboard</NavLink>
                    <NavLink to="/profile">Profile</NavLink>
                    <NavLink to="/settings">Settings</NavLink>
                </div>
                <div className="nav-right">
                    <button className="btn logout-btn" onClick={handleLogout}>Logout</button>
                </div>
            </nav>
        );
    }

    // Default navbar for other roles
    return (
        <nav>
            <div className="hamburger" onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className={`nav-left ${isOpen ? 'open' : ''}`}>
                <NavLink to="/dashboard">Dashboard</NavLink>
                <NavLink to="/profile">Profile</NavLink>
                <NavLink to="/create-invitation">Create Invitation</NavLink>
                <NavLink to="/settings">Settings</NavLink>
            </div>
            <div className="nav-right">
                <button className="btn logout-btn" onClick={handleLogout}>Logout</button>
            </div>
        </nav>
    );
};

export default NavigationBar;
