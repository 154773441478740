import React from 'react';

const TeamMessage = () => {
    return (
        <div className="team-message-container">
            <h1 className="team-message-header">A Quick Message from Our Team</h1>
            <p>Welcome to elevere! Or at least, welcome to the precursor to our full platform, a mini invitation app.</p>
            <p>Behind the scenes, we're passionately developing a full-featured platform that will offer an expansive array of tools and resources, tailored to empower your professional growth. In the meantime, we decided to build out the invitation feature first, enabling top professionals like you to have a say in the platform, and begin shaping the network and community before day one.</p>
            <p>As a full user, you get two invitations each month, to invite other top professionals you have directly worked with. Think of these invitations as a reflection of your professional network and endorsement. They are your way of saying, 'I stand by these individuals.' We're building a community where quality and trust form the cornerstone, not just the number of connections.</p>
            <p><strong>As each user builds out their own trusted network through invitations, adding their connections, expertise, and endorsements, they enhance the collective knowledge and resource pool of the platform. elevere enables this to happen at scale, creating a high-trust environment for interaction, providing access to trusted networks extending far beyond our own.</strong></p>
            <p>Should you have any questions about the platform, or suggestions on how we can make elevere work better for you, please reach out to our team at hello@elvrlabs.com, or our founder at joel@elvrlabs.com.</p>
            <p>Thank you for exploring what elevere has to offer. We look forward to showing you our vision of a high-trust talent network that will change the way we interact and discover opportunities.</p>
            <p>The team at elevere labs</p>
        </div>
    );
};

export default TeamMessage;
