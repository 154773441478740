import React from 'react';

const PasswordChangedBanner = ({ onClose }) => {
  // Automatically close the banner after 12 seconds
  React.useEffect(() => {
    const timer = setTimeout(onClose, 12000); // 12 seconds
    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [onClose]);

  const message = "Password Changed"; // Hardcoded message

  return (
    <div className="top-banner password-changed-banner" onClick={onClose}>
      <span className="banner-message">{message}</span>
      <button onClick={onClose} className="close-banner"></button>
    </div>
  );
};

export default PasswordChangedBanner;
