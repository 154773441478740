import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // To access passed state and navigate
import { AuthContext } from '../context/AuthContext';
import ReauthenticationForm from '../components/ReauthenticationForm';
import { updatePassword } from 'firebase/auth'; // Import the updatePassword method
import PasswordChangedBanner from '../components/PasswordChangedBanner';

const ReauthenticationPage = () => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation(); // To access the navigation state
    const [newPassword, setNewPassword] = useState(''); // State to hold the new password
    const [showPasswordChangedBanner, setShowPasswordChangedBanner] = useState(false); // State to control success banner visibility
    
    useEffect(() => {
        // Check if there's a state passed to this component with the new password
        if (location.state?.newPassword) {
            setNewPassword(location.state.newPassword);
        }
    }, [location.state]);

    const handleSuccess = async () => {
        // After successful reauthentication, update the password if newPassword is set
        if (newPassword && currentUser) {
            try {
                await updatePassword(currentUser, newPassword);

                // Show success banner before redirecting
                setShowPasswordChangedBanner(true);
                // Wait for a few seconds before redirecting to dashboard
                setTimeout(() => {
                    navigate('/dashboard');
                }, 1200); // Adjust time as needed
            } catch (error) {
                console.error('Failed to update password after re-authentication:', error);
                // Handle error (e.g., show error message)
            }
        } else {
            navigate('/dashboard'); // Fallback redirect if no newPassword is set
        }
    };

    if (!currentUser) {
        return <p>Please log in.</p>; // or redirect to login page
    }

    return (
        <div className="reauthentication-page-container">
            <h1 className="reauthentication-header">Please Sign in Again</h1>
            <ReauthenticationForm onSuccess={handleSuccess} />
            {showPasswordChangedBanner && <PasswordChangedBanner onClose={() => setShowPasswordChangedBanner(false)} />}
        </div>
    );
};

export default ReauthenticationPage;
