import React from 'react';
import { useNavigate } from 'react-router-dom';

const SupportButton = () => {
    const navigate = useNavigate(); // Hook for navigation

    const handleClick = () => {
        navigate('/faq'); // Navigate to the support page on click
    };

    return (
        <button className="floating-support-btn" onClick={handleClick}>
            Need Help?
        </button>
    );
};

export default SupportButton;
