import React, { useState, useContext } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../context/AuthContext'; // Adjust the import path as needed
import googleSignInImage from '../media/SignIn_Google.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate(); // Initialize navigate function for redirection

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const userDocRef = doc(firestore, "users", userCredential.user.uid);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                setUser(docSnap.data());
                navigate('/dashboard'); // Redirect to dashboard after successful login
            } else {
                setError("User does not exist. Please register.");
            }
        } catch (error) {
            setError('Failed to login: ' + error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const userDocRef = doc(firestore, "users", result.user.uid);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                setUser(docSnap.data());
                navigate('/dashboard'); // Redirect to dashboard after successful Google login
            } else {
                await signOut(auth); // Sign out the user if they do not exist in Firestore
                navigate('/login'); // Redirect the user to the registration page or home page
                setError("User does not exist in our records. Please register, or contact support.");
            }
        } catch (error) {
            setError('Failed to login with Google: ' + error.message);
        }
    };

    return (
        <div>
            <form onSubmit={handleLogin} className="form-container">
                <div className="form-group">
                    <label className="form-label">Email:</label>
                    <input
                        type="email"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Password:</label>
                    <input
                        type="password"
                        className="form-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn login-form-login-btn">Login</button>
            </form>
            <img src={googleSignInImage} alt="Sign in with Google" className="googleSignInImage" onClick={handleGoogleSignIn} style={{ cursor: 'pointer' }} />
            {error && <p className="form-error">{error}</p>}
        </div>
    );
};

export default LoginForm;
