import React, { useState } from 'react';
import miniInvitationAppSlide from '../media/mini-invitation-app-slide.png';
import twoInvitationsSlide from '../media/each-month-two-invitations-slide-2.png';


const slides = [
    {
        title: "The seeds of something more",
        text: "",
        image: miniInvitationAppSlide
    },
    {
        title: "Invite with Intent",
        text: "",
        image: twoInvitationsSlide
    },

];

const OnboardingSlideshowFullUser = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const previousSlide = () => {
        setCurrentSlide((prev) => prev === 0 ? slides.length - 1 : prev - 1);
    };

    return (
        <div className="slideshow-container">
            <div className="slide" key={slides[currentSlide].title}>
                <h2>{slides[currentSlide].title}</h2>
                <p>{slides[currentSlide].text}</p>
                <img src={slides[currentSlide].image} alt="Slide image" />
            </div>
            <button onClick={previousSlide} className="slide-btn previous-btn">◀</button>
            <button onClick={nextSlide} className="slide-btn next-btn">▶</button>
        </div>
    );
};

export default OnboardingSlideshowFullUser;
