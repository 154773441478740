import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import DashboardPage from './pages/DashboardPage';
import HomePage from './pages/HomePage';
import CreateInvitationPage from './pages/CreateInvitationPage';
import ManageInvitationsPage from './pages/ManageInvitationsPage';
import LandingPage from './pages/LandingPage';
import EnterInvitationPage from './pages/EnterInvitationPage';
import UserRegistrationPage from './pages/UserRegistrationPage';
import MiniDashboardPage from './pages/MiniDashboardPage';
import UserProfilePage from './pages/UserProfilePage';
import UserSettingsPage from './pages/UserSettingsPage';
import './App.css';
import NavigationBar from './components/navigation/NavigationBar';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ReauthenticationPage from './pages/ReauthenticationPage';
import RedirectElevereLabsWebsite from './components/RedirectToElevereLabsWebsite';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import FAQ from './pages/FAQ';
import SupportButton from './components/SupportButton';
import WelcomeNewFullUserPage from './pages/WelcomeNewFullUserPage';
import WelcomeNewPartialUserPage from './pages/WelcomeNewPartialUserPage';
import WelcomeNewFullUserPage2 from './pages/WelcomeNewFullUserPage2';

function App() {
  return (
    <AuthProvider>
    <div className="App">

        <Router>
          <NavigationBar /> {/* This makes the navigation bar appear on every page */}
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/reset-password" element={<ForgotPasswordPage />} />              
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/create-invitation" element={<CreateInvitationPage />} />
                <Route path="/manage-invitations" element={<ManageInvitationsPage />} />
                <Route path="/landing-page" element={<LandingPage />} />
                <Route path="/enter-invitation" element={<EnterInvitationPage />} />
                <Route path="/register" element={<UserRegistrationPage />} />
                <Route path="/mini-dashboard" element={<MiniDashboardPage />} />
                <Route path="/user-registration" element={<UserRegistrationPage />} />
                <Route path="/profile" element={<UserProfilePage />} />
                <Route path="/settings" element={<UserSettingsPage />} />
                <Route path="/change-password" element={<ChangePasswordPage />} />
                <Route path="/reauthentication" element={<ReauthenticationPage />} />
                <Route path="/redirect-to-elvr-labs" element={<RedirectElevereLabsWebsite />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/welcome-new-full-users" element={<WelcomeNewFullUserPage />} />
                <Route path="/welcome-new-full-users-2" element={<WelcomeNewFullUserPage2 />} />
                <Route path="/welcome-new-first-time-users" element={<WelcomeNewPartialUserPage />} />
                /


                {/* Add other routes as needed */}
            </Routes>
          <SupportButton />
        </Router>
    </div>
    </AuthProvider>
  );
}

export default App;
