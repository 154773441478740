import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const ProfileForm = () => {
    const { idToken, currentUser } = useContext(AuthContext);
    // Initialize all fields with empty strings to ensure they are controlled
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        job: '',
        company: '',
        email: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.uid) {
            axios.get(`https://silver-fragment-419202.et.r.appspot.com/profile`, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            })
            .then(response => {
                console.log('Fetched profile data:', response.data);
                // Ensure every field has a default value to avoid undefined
                setProfileData({
                    firstName: response.data.firstName || '',
                    lastName: response.data.lastName || '',
                    job: response.data.job || '',
                    company: response.data.company || '',
                    email: response.data.email || '',
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
            });
        }
    }, [idToken, currentUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.patch(`https://silver-fragment-419202.et.r.appspot.com/profile`, profileData, {
            headers: { 'Authorization': `Bearer ${idToken}` }
        })
        .then(() => {
            setIsEditMode(false);
        })
        .catch(error => {
            console.error('Error updating profile:', error);
        });
    };

    return (
        <div className="profile-form-container">
            <form className="profile-form" onSubmit={handleSubmit}>
                {/* Input fields remain the same, as they are now ensured to be controlled */}
                <div className="form-group">
                    <label className="form-label">First Name:</label>
                    <input className="form-input" type="text" name="firstName" value={profileData.firstName} onChange={handleInputChange} disabled={!isEditMode} />
                </div>
                <div className="form-group">
                    <label className="form-label">Last Name:</label>
                    <input className="form-input" type="text" name="lastName" value={profileData.lastName} onChange={handleInputChange} disabled={!isEditMode} />
                </div>
                <div className="form-group">
                    <label className="form-label">Job:</label>
                    <input className="form-input" type="text" name="job" value={profileData.job} onChange={handleInputChange} disabled={!isEditMode} />
                </div>
                <div className="form-group">
                    <label className="form-label">Company:</label>
                    <input className="form-input" type="text" name="company" value={profileData.company} onChange={handleInputChange} disabled={!isEditMode} />
                </div>
                <div className="form-group">
                    <label className="form-label">Email:</label>
                    <input className="form-input" type="email" name="email" value={profileData.email} onChange={handleInputChange} disabled={!isEditMode} />
                </div>
                <div className="profile-form-button-container">
                    <button className="profile-form-button" type="submit" disabled={!isEditMode}>Save Changes</button>
                    <button className="edit-mode-toggle-btn profile-form-button" type="button" onClick={() => setIsEditMode(!isEditMode)}>
                            {isEditMode ? 'Cancel' : 'Edit'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProfileForm;
