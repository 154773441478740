import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBIDXtuw7nmrWN-bQfaw0mRP7kbSWdPVn8",
  authDomain: "invitationdec23.firebaseapp.com",
  projectId: "invitationdec23",
  storageBucket: "invitationdec23.appspot.com",
  messagingSenderId: "247768132563",
  appId: "1:247768132563:web:007cd4ea79977d401b1d26",
  measurementId: "G-M32T0YT8XN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const firestore = getFirestore(app);