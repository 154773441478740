import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from React Router v6

const LandingPage = () => {
    const navigate = useNavigate();

    // Function to navigate to login page
    const navigateToLogin = () => {
        navigate('/login'); // Replace '/login' with your login page route
    };

    // Function to navigate to invitation input page
    const navigateToInvitationInput = () => {
        navigate('/enter-invitation'); // Replace '/enter-invitation' with your invitation input page route
    };

    return (
        <div className="landingpage">
            <h1>Welcome to Our Application!</h1>
            <p>Get started by logging in or entering your invitation code.</p>
            <button onClick={navigateToLogin}>Login</button>
            <button onClick={navigateToInvitationInput}>Enter Invitation Code</button>
        </div>
    );
};

export default LandingPage;
