import React from 'react';

const GeneratedCodeDisplay = ({ code }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(code);
        alert("Copied to clipboard");
    };

    return (
        <div>
            {code && (
                <>
                    <p>Generated Code: {code}</p>
                    <button onClick={copyToClipboard}>Copy to Clipboard</button>
                </>
            )}
        </div>
    );
};

export default GeneratedCodeDisplay;