import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom'; // Import useNavigate hook
import axios from 'axios';
import WelcomeMessage from '../components/WelcomeMessage';
import DashboardContent from '../components/DashboardContent';

const DashboardPage = () => {
    const { currentUser, idToken, userRole, isLoading } = useContext(AuthContext);
    const [firstName, setFirstName] = useState('');
    const navigate = useNavigate(); // Hook for programmatically navigating

    useEffect(() => {
        if (currentUser && currentUser.uid && !isLoading) {
            axios.get(`https://silver-fragment-419202.et.r.appspot.com/profile`, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            })
            .then(response => {
                setFirstName(response.data.firstName);
            })
            .catch(error => {
                console.error('Error fetching user profile details:', error);
            });
        }
    }, [currentUser, idToken, isLoading]);

    useEffect(() => {
        // Handle redirection or refresh if the role doesn't match expected roles
        if (!isLoading && !['admin', 'poweruser', 'fulluser', 'partialuser'].includes(userRole)) {
            setTimeout(() => {
                window.location.reload(); // Force a full page reload
            }, 1000);
        }
    }, [userRole, isLoading]);

    if (isLoading) {
        return <div>Loading...</div>; // Or some loading spinner component
    }

    if (!currentUser) {
        return <Navigate to="/login" replace />;
    }

    if (['admin', 'poweruser', 'fulluser'].includes(userRole)) {
        return (
            <div>
                <WelcomeMessage username={`, ${firstName}!` || ''} />
                <DashboardContent />
            </div>
        );
    } else if (userRole === 'partialuser') {
        return <Navigate to="/mini-dashboard" replace />;
    } else {
        return null; // Will not render anything until the redirect or reload is triggered
    }
};

export default DashboardPage;
