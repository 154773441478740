// UserRegistrationPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import googleSignInImage from '../media/SignIn_Google.png'

const UserRegistrationPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();

    // Extracting` invitation code from URL
    const queryParams = new URLSearchParams(location.search);
    const invitationCode = queryParams.get('code');

    useEffect(() => {
        if (!invitationCode) {
            navigate('/'); // Redirect to home if no invitation code is found
        }
    }, [invitationCode, navigate]);

    const handleRegistration = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await postRegistrationData(userCredential.user.uid);
        } catch (error) {
            console.error(error); // Log the detailed error for debugging
            handleErrorMessage(error);
        }
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            await postRegistrationData(result.user.uid, result.user.displayName, result.user.email);
        } catch (error) {
            console.error(error);
            handleErrorMessage(error);
        }
    };

    const postRegistrationData = async (uid, displayName = '', email = '') => {
        const [firstNameFromGoogle, lastNameFromGoogle] = displayName ? displayName.split(' ') : [firstName, lastName];
        const response = await axios.post(`https://silver-fragment-419202.et.r.appspot.com/partial_registration`, {
            invitee: uid,
            code1: invitationCode,
            firstName: firstNameFromGoogle,
            lastName: lastNameFromGoogle,
            email,
        });

        console.log(response.data);
        if (response.data.success) {
            setMessage('Registration successful. Welcome to elevere');
            setTimeout(() => navigate('/mini-dashboard'), 1250); // Redirect to MiniDashboardPage after 1.25 seconds
        } else {
            setMessage(response.data.message || 'Registration failed.');
        }
    };

    const handleErrorMessage = (error) => {
        if (error.code) {
            // Display Firebase error message
            setMessage(`Firebase error: ${error.message}`);
        } else {
            // Generic error message for other types of errors
            setMessage('Error in registration.');
        }
    };

    return (
        <div className="user-registration-container">
            <h1 className="user-registration-header">User Registration</h1>
            <form onSubmit={handleRegistration} className="user-registration-form">
                <input 
                    className="user-registration-input"
                    type="text" 
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    required
                />
                <input 
                    className="user-registration-input"
                    type="text" 
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    required
                />
                <input 
                    className="user-registration-input"
                    type="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <input 
                    className="user-registration-input"
                    type="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button className="user-registration-submit" type="submit">Register</button>
                <img src={googleSignInImage} alt="Sign up with Google" className="googleSignInImage" onClick={handleGoogleSignIn} style={{cursor: 'pointer'}} />
            </form>
            {message && <p className="user-registration-message">{message}</p>}
        </div>
    );
};

export default UserRegistrationPage;
