import React from 'react';
import { Link } from 'react-router-dom';

const RedirectLinktoRegister = () => {
    return (
        <p className="redirect-link-text">
            Don't have an account? <Link to="/redirect-to-elvr-labs">Join waitlist</Link> or <Link to="/enter-invitation">enter an invitation code</Link>
        </p>
    );
};

export default RedirectLinktoRegister;
