import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import InvitationStats from './InvitationStats';

const DashboardContent = () => {
    return (
        <div>
            <p>Welcome to elevere</p>
            {/* Add a Link to ManageInvitationsPage */}
            <InvitationStats />
            <Link to="/manage-invitations" className="btn manage-invitations-btn">Manage Invitations</Link>
        </div>
    );
};

export default DashboardContent;