import React from 'react';
import { Link } from 'react-router-dom';

const RedirectLinkToSignIn = () => {
    return (
        <p className="redirect-link-text">
            Already have an account? <Link to="/login">Log in</Link>.
        </p>
    );
};

export default RedirectLinkToSignIn;
