import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const ProfilePictureUpload = () => {
    const { idToken } = useContext(AuthContext);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const showMessage = (message) => {
        setUploadMessage(message);
        setTimeout(() => setUploadMessage(''), 4000); // Hide message after 4 seconds
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!selectedFile) {
            showMessage('Please select a file to upload.');
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post('https://silver-fragment-419202.et.r.appspot.com/upload-profile-picture', formData, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            showMessage('Profile picture uploaded successfully.');
            setSelectedFile(null); // Optionally clear the selected file
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            showMessage('Failed to upload profile picture.');
        });
    };

    return (
        <>
            <h2 className='upload-picture-header'>Upload Profile Picture</h2>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileSelect} />
                <button className='upload-picture-btn' type="submit">Upload Picture</button>
            </form>
            {uploadMessage && <div className="upload-message">{uploadMessage}</div>}
        </>
    );
};

export default ProfilePictureUpload;
