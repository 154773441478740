import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const OutstandingInvitationsCount = () => {
    const [remainingInvitations, setRemainingInvitations] = useState(0);
    const [error, setError] = useState('');
    const { currentUser, idToken } = useContext(AuthContext);

    useEffect(() => {
        if (currentUser && idToken) {
            fetchRemainingInvitations();
        }
    }, [currentUser, idToken]);

    const fetchRemainingInvitations = async () => {
        try {
            const response = await axios.get(`https://silver-fragment-419202.et.r.appspot.com/get_remaining_invitations/${currentUser.uid}`, {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            });
            setRemainingInvitations(response.data.remaining_invitations);
        } catch (err) {
            setError('Failed to fetch remaining invitations');
        }
    };

    return (
        <div>
            {error ? <p>Error: {error}</p> : <p>Remaining Invitations: {remainingInvitations}</p>}
        </div>
    );
};

export default OutstandingInvitationsCount;
