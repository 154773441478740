import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {

    return (
        <div className="home-container">
            <h1>Welcome to elevere</h1>
            <p>The trusted talent network.</p>
            <div className="homepage-button-container">
                <Link to="/login" className="homepage-login-btn">Login</Link>
                <Link to="/enter-invitation" className="homepage-enter-code-btn">Enter Invitation Code</Link>
            </div>
        </div>
    );
};

export default HomePage;