import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // Ensure this import points to your Firebase config file
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [idToken, setIdToken] = useState(null);
    const [userRole, setUserRole] = useState(null); // State for the user role
    const [loading, setLoading] = useState(true); // Corrected the state name to 'loading' for consistency

    // Initialize Firestore
    const firestore = getFirestore();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if (user) {
                const token = await user.getIdToken(true);
                setIdToken(token);

                // Fetch user role from Firestore
                const userDocRef = doc(firestore, 'users', user.uid);
                getDoc(userDocRef).then(docSnap => {
                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setUserRole(userData.role); // Assuming 'role' field exists in user document
                    } else {
                        console.error("No user document found");
                        setUserRole(null); // Reset role if user document doesn't exist
                    }
                    setLoading(false); // Move setLoading(false) here to ensure it is set after the role is fetched
                }).catch(error => {
                    console.error('Error fetching user role:', error);
                    setUserRole(null); // Reset role on error fetching user document
                    setLoading(false); // Ensure loading is set to false even on error
                });
            } else {
                setIdToken(null);
                setUserRole(null); // Reset role on logout
                setLoading(false); // Set loading to false when there's no user
            }
            setCurrentUser(user); // Moved this line out of the if-else block to ensure it's always executed
        });

        return unsubscribe; // Return the unsubscribe function to clean up the subscription
    }, [firestore]);

    return (
        <AuthContext.Provider value={{ currentUser, idToken, setIdToken, userRole, setUserRole, loading }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
