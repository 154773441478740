import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../context/AuthContext';
import TeamMessage from './TeamMessage';

const WelcomeNewFullUserPage = () => {
    const navigate = useNavigate();
    const { currentUser, setUserRole } = useContext(AuthContext); // Access setUserRole and currentUser from context

    useEffect(() => {
        const fetchUserRole = async () => {
            if (currentUser) {
                const firestore = getFirestore();
                const userDocRef = doc(firestore, 'users', currentUser.uid);
                try {
                    const docSnap = await getDoc(userDocRef);
                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setUserRole(userData.role); // Update the role in the context
                    } else {
                        console.error("No user document found");
                    }
                } catch (error) {
                    console.error('Error fetching user role:', error);
                }
            }
        };

        fetchUserRole();
    }, [currentUser, setUserRole]);

    const handleEnterClick = () => {
        navigate('/welcome-new-full-users-2'); // Navigate to the main dashboard
    };

    return (
        <div className="welcome-container">
            <div className='welcome-new-user-message-container'> 
                <h1>Welcome to Elevere!</h1>
                <p className='welcome-new-user-message'>Congratulations, you now have full access to elevere, the trusted talent network.</p>
                <p className='welcome-new-user-message'>Each calendar month, you will receive two invitations you can use to vouch for others that you want to bring into our community.</p>
            </div>
            <TeamMessage />
            <button onClick={handleEnterClick} className="enter-app-btn">Continue</button>
        </div>
    );
};

export default WelcomeNewFullUserPage;