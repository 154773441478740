// SignUpPage.jsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import SignUpForm from '../components/SignUpForm';
import SocialLoginButtons from '../components/SocialLoginButtons';
import RedirectLinkToSignIn from '../components/RedirectLinkToSignIn';

const SignUpPage = () => {
    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div className="signup-page">
            <h1>Sign Up</h1>
            <SignUpForm />
            <SocialLoginButtons />
            <RedirectLinkToSignIn />
        </div>
    );
};

export default SignUpPage;
