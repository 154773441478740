import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import RevokeInvitation from './RevokeInvitation';
import InvitationDetails from './InvitationDetails';

const InvitationList = ({ onDetails, refreshCount }) => {
    const [invitations, setInvitations] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser, idToken } = useContext(AuthContext);
    const [detailCode, setDetailCode] = useState(null);

    useEffect(() => {
        const fetchInvitations = async () => {
            if (!currentUser || !idToken) {
                console.error('Missing currentUser or idToken');
                return;
            }

            setLoading(true);
            try {
                const response = await axios.get(`https://silver-fragment-419202.et.r.appspot.com/get_user_sent_invitations/${currentUser.uid}`, {
                    headers: { 'Authorization': `Bearer ${idToken}` },
                });
                // Assuming response.data is an array of invitations
                const sortedInvitations = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setInvitations(sortedInvitations);
            } catch (error) {
                console.error("Error fetching invitations:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchInvitations();
    }, [currentUser, idToken, refreshCount]);

    const toggleDetails = (code) => {
        setDetailCode(detailCode === code ? null : code);
    };
    
    if (loading) return <p>Loading invitations...</p>;

    return (
        <div className="invitation-list">
            {invitations.map((invite) => (
                <div className={`invitation-item ${!invite.active ? 'invitation-item-inactive' : ''}`} key={invite.code}>
                    <div className="invitation-item-header">
                        <h3 style={{ color: !invite.active ? '#d3d3d3' : 'inherit' }}>Code: {invite.code}</h3>
                        <p className={`status-indicator ${invite.active ? 'active' : invite.revoked ? 'revoked' : invite.used ? 'used' : invite.expired ? 'expired' : 'inactive'}`}>
                            {invite.active ? 'Active' : invite.revoked ? 'Revoked' : invite.used ? 'Used' : invite.expired ? 'Expired' : 'Inactive'}
                        </p>
                        {invite.active && <RevokeInvitation code={invite.code} onRevoked={() => {}} />}
                        <button className={`invitation-details-button ${!invite.active ? 'invitation-details-button-inactive' : ''}`} onClick={() => toggleDetails(invite.code)}>
                            {detailCode === invite.code ? 'Hide Details' : 'Show Details'}
                        </button>
                    </div>
                    {detailCode === invite.code && <InvitationDetails code={invite.code} />}
                </div>
            ))}
        </div>
    );
};

export default InvitationList;
