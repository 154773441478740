import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const RevokeInvitation = ({ code, onRevoked }) => {
    const { currentUser, idToken } = useContext(AuthContext);
    const [message, setMessage] = useState(''); // State to manage the message display

    if (!currentUser || !idToken) {
        console.error('Missing currentUser or idToken');
        return <div>Authentication required.</div>;
    }

    const revokeInvitation = async () => {
        try {
            await axios.post(
                `https://silver-fragment-419202.et.r.appspot.com/revoke_invitation`,
                { code },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    }
                }
            );
            onRevoked( code );
            setMessage('Invitation revoked.'); // Set message to display
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error("Error revoking invitation:", error);
            setMessage('Failed to revoke invitation.'); // Set error message if the request fails
        }
    };

    return (
        <div>
            <button className="revoke-invitation-button" onClick={revokeInvitation}>Revoke</button>
            {message && <p className="revoke-message">{message}</p>}
        </div>
    )            
};

export default RevokeInvitation;
