import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext'; // Adjust the import path as needed
import { Navigate } from 'react-router-dom'; // For redirecting non-verified users

const faqData = {
  accountManagement: [
    // Questions related to account management
    { question: "How do I update my profile information?", answer: "You can update your profile information such as your name and email address by navigating to the Profile page in your account settings." },
    { question: "How to reset my password?", answer: "Go to the login page and click on 'Forgot Password?' to reset your password using your registered email." },
  ],
  appFunctionality: [
    { question: "What is elevere?", answer: "elevere is a high-trust talent network, creating a space where top professionals can confidently interact with other users, as well as businesses" },
    { question: "What exactly is this mini invitation app?", answer: "This mini invitation app is the first step towards our full featured platform. While we're building that, we wanted to enable top talent to have a say, and begin shaping the elevere network and community" },
    { question: "Why is elevere focusing on an invitation-based model?", answer: "We believe there is significant value in creating a high-trust network, where individuals can have confidence in their interactions with all other users. By using invitations, we ensure that every new member is recommended by 2 existing users, maintaining an integrity, confidence, and trust in the professional community we are building." },
    { question: "How do I register?", answer: "Users can register by invitation only. You will need 2 valid invitations from different users to join. Use the invitation code provided to you by confirmed users, and follow the steps to create an account." },
  ],
  invitations: [
    // Questions related to invitations
    { question: "What should I consider before sending an invitation?", answer: "Reflect on your professional interactions with the individual. Would you vouch for their expertise and character in a network of high achievers? If yes, they are likely a great fit for elevere. Remember, they will still need another invite from a different user to get full access." },
    { question: "How can I ensure that my invitations are impactful?", answer: "Invite professionals you've worked with directly and who you believe will actively contribute to and benefit from the elevere network. Your invitations help shape the community and the opportunities within it." },
    { question: "I have two valid invitation codes but I cannot seem to join", answer: `To promote diversity of our userbase and to reduce fraud, we require one degree of seperation in the 2 invitation codes used for registration. What this means is that if user Z registered using a code from User A and user B, new users cannot use codes from both User Z and User A/User B, since there is no degree of seperation. Reach out to our team at support@elvrlabs.com if you need any help` },
    { question: "How do I revoke an invitation?", answer: "Navigate to the Manage Invitations page, where you can see all your sent invitations and revoke any that are still active." },
    { question: "When do invitations expire?", answer: "Each invitation expires 31 days from the date it is created, unless used or revoked before then." },
    { question: "How can I check the status of my sent invitations?", answer: "You can view the status of your sent invitations by accessing the Manage Invitations page from your user dashboard." },
    { question: "What happens if my invitation code is used?", answer: "The status of the code will be changes to 'Used', and you can check the name of the user who registered using that code" },
    { question: "Can I create multiple invitations?", answer: "Yes, you can create multiple invitations simultaneously, up to the invitation limit of 2 each month" },
  ],
};

const FAQSection = ({ sectionData, sectionTitle, toggleFAQ, openIndex }) => (
  <div className="faq-section">
    <h2 className="faq-section-title">{sectionTitle}</h2>
    {sectionData.map((faq, index) => (
      <div key={index} className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(index)}>
          {faq.question}
        </button>
        {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
      </div>
    ))}
  </div>
);

const FAQ = () => {
  const { currentUser } = useContext(AuthContext);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-page-container">
      <h1 className="faq-header">Frequently Asked Questions</h1>
      {/* Account Management Section */}
      <FAQSection
        sectionData={faqData.appFunctionality}
        sectionTitle="elevere"
        toggleFAQ={toggleFAQ}
        openIndex={openIndex}
      />
      {/* Invitations Section */}
      <FAQSection
        sectionData={faqData.invitations}
        sectionTitle="Invitations"
        toggleFAQ={toggleFAQ}
        openIndex={openIndex}
      />
      {/* Account Management */}
      <FAQSection
        sectionData={faqData.accountManagement}
        sectionTitle="Account Management"
        toggleFAQ={toggleFAQ}
        openIndex={openIndex}
      />
      <p className="faq-contact-support">
        Can't find what you're looking for? Contact our support team at{' '}
        <a href="mailto:support@elvrlabs.com" className="faq-support-link">
          support@elvrlabs.com
        </a>
      </p>
    </div>
  );
};

export default FAQ;
