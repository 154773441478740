import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
import { AuthContext } from '../context/AuthContext';
import { updatePassword } from 'firebase/auth';

const ChangePasswordForm = ({ onSuccess }) => {
    const navigate = useNavigate(); // Hook for programmatic navigation
    const { currentUser } = useContext(AuthContext);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            setError('');
            setMessage('');
            await updatePassword(currentUser, password);
            setMessage('Password updated successfully');
            // Clearing the form fields after successful password update
            setPassword(''); 
            setConfirmPassword('');
            // Executing any additional logic defined in the onSuccess callback, if provided
            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error('Failed to update password:', error);
            if (error.code === "auth/requires-recent-login") {
                navigate('/reauthentication', { state: { newPassword: password } });
                alert("For your security, please sign in again to confirm your identity before changing your password.");
            } else {
                setError('Failed to update password');
            }
        }
    };

    return (
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {message && <p style={{ color: 'green' }}>{message}</p>}
            <form onSubmit={handleSubmit} className="change-password-form">
                <div>
                    <label>New Password:</label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <div>
                    <label>Confirm Password:</label>
                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </div>
                <button type="submit">Change Password</button>
            </form>
        </div>
    );
};

export default ChangePasswordForm;
