import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import InvitationForm from '../components/InvitationForm';
import GeneratedCodeDisplay from '../components/GeneratedCodeDisplay';
import LoadingIndicator from '../components/LoadingIndicator';
import ErrorDisplay from '../components/ErrorDisplay';
import OutstandingInvitationsCount from '../components/OutstandingInvitationsCount';
import { Link } from 'react-router-dom'; // Import Link for navigation

const CreateInvitationPage = () => {
    const [generatedCode, setGeneratedCode] = useState('');
    const [error, setError] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const { currentUser, userRole, loading } = useContext(AuthContext); // Include loading state

    // Handling loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    // Check if the user has the required role
    if (!['admin', 'poweruser', 'fulluser'].includes(userRole)) {
        return <p>Access Denied. You do not have the permission to create invitations.</p>;
    }

    const handleCodeGenerated = (code) => {
        setGeneratedCode(code);
        setError('');
        setRefreshKey(prev => prev + 1);
    };

    const handleError = (errorMessage) => {
        setError(errorMessage);
        setGeneratedCode('');
    };

    return (
        <div>
            <h1 className='create-invitation-code-header'>Create Invitation Code</h1>
            <OutstandingInvitationsCount key={refreshKey} />
            <InvitationForm onCodeGenerated={handleCodeGenerated} onError={handleError} />
            {generatedCode && <GeneratedCodeDisplay code={generatedCode} />}
            {error && <ErrorDisplay error={error} />}
            <h3 className='generate-a-code-to-invite-top-talent-header'>Generate a code to invite other top talent to elevere</h3>
            <div className='create-invitation-message-container'>
                <p className='create-invitation-message'>Each new user needs two invitations to join<sup>#</sup></p>
                <p className='create-invitation-message'>Your invite is meant to be a recommendation of the user, and each user's profile will display the 2 inviters that vouched for them</p>
            </div>
            <Link to="/manage-invitations" className="manage-invitations-link-in-create-invitations-page">Manage Invitations</Link>
        </div>
    );
};

export default CreateInvitationPage;
