import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import InvitationList from '../components/InvitationList';
import RevokeInvitation from '../components/RevokeInvitation';
import InvitationDetails from '../components/InvitationDetails';
import { AuthContext } from '../context/AuthContext'; // Ensure this is correctly imported

const ManageInvitationsPage = () => {
    const { userRole, loading } = useContext(AuthContext);
    const [selectedCode, setSelectedCode] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [showRevoke, setShowRevoke] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0); // New state to trigger refresh

    const handleRevoke = (code) => {
        console.log("Revoking invitation with code:", code);
        setSelectedCode(code);
        setShowRevoke(true);
    };

    const onRevoked = () => {
        setShowRevoke(false);
        setSelectedCode(null); // Reset the selectedCode when revocation is done
        console.log(refreshCount)
        setRefreshCount(prevCount => prevCount + 1); // This will trigger re-fetching or re-rendering
        // Increment refreshCount to trigger re-fetch
        console.log(refreshCount)
    };

    const handleDetails = (code) => {
        setSelectedCode(code);
        setShowDetails(true);
    };

    if (loading) {
        return null; // Or a loading indicator/spinner
    }

    if (!['admin', 'poweruser', 'fulluser'].includes(userRole)) {
        return <Navigate to="/mini-dashboard" />;
    }

    return (
        <div className="manage-invitations-container">
            <h1 className="manage-invitations-header">Manage Invitations</h1>
            {/* Pass refreshCount as a prop to InvitationList */}
            <InvitationList onRevoke={handleRevoke} onDetails={handleDetails} refreshCount={refreshCount} />
            {showRevoke && selectedCode && (
                <RevokeInvitation code={selectedCode} onRevoked={onRevoked} />
            )}
            {showDetails && selectedCode && (
                <InvitationDetails code={selectedCode} />
            )}
        </div>
    );
};

export default ManageInvitationsPage;