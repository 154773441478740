import React, { useState } from 'react';
import explanationSlide1 from '../media/first-time-user-second-invitation-explanation.png';
import explanationSlideTopTalent from '../media/where-top-talent-connect.png';
import explanationSlideFullAccess from '../media/early-access-extend-invitations.png';

const slidesPartial = [
    {
        title: "Got a second (invitation)?",
        text: "",
        image: explanationSlide1
    },
    {
        title: "Community of Top Talent",
        text: "",
        image: explanationSlideTopTalent
    },
    {
        title: "Unlocking full access",
        text: "",
        image: explanationSlideFullAccess
    }
];

const OnboardingSlideshowPartialUsers = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slidesPartial.length);
    };

    const previousSlide = () => {
        setCurrentSlide((prev) => prev === 0 ? slidesPartial.length - 1 : prev - 1);
    };

    return (
        <div className="slideshow-container-partial">
            <div className="slide-partial" key={slidesPartial[currentSlide].title}>
                <h2>{slidesPartial[currentSlide].title}</h2>
                <p>{slidesPartial[currentSlide].text}</p>
                <img src={slidesPartial[currentSlide].image} alt="Slide image" />
            </div>
            <button onClick={previousSlide} className="slide-btn-partial previous">◀</button>
            <button onClick={nextSlide} className="slide-btn-partial next">▶</button>
        </div>
    );
};

export default OnboardingSlideshowPartialUsers;