import React, { useEffect } from 'react';

const RedirectElevereLabsWebsite = () => {
  useEffect(() => {
    window.location.href = 'https://elvrlabs.com';
  }, []);

  return null; // Render nothing since we're just redirecting
};

export default RedirectElevereLabsWebsite;