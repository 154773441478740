import React, { useContext } from 'react';
import { useNavigate, Navigate } from 'react-router-dom'; // Import Navigate
import InvitersDisplay from '../components/InvitersDisplay';
import UserProfileDetails from '../components/UserProfileDetails';
import { AuthContext } from '../context/AuthContext';

const UserProfilePage = () => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!currentUser) {
        // Redirect to the login page instead of displaying a message
        return <Navigate to="/login" replace />;
    }

    return (
        <div className="user-profile-page">
            <h1 className="page-title">User Profile</h1>
            <UserProfileDetails />
            <InvitersDisplay />
            <button className="btn edit-profile-btn" onClick={() => navigate('/settings')}>Edit Profile</button>
        </div>
    );
};

export default UserProfilePage;