import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../context/AuthContext';
import OnboardingSlideshowPartialUser from '../components/OnboardingSlideshowPartialUser';

const WelcomeNewPartialUserPage = () => {
    const navigate = useNavigate();
    const { currentUser, setUserRole } = useContext(AuthContext);

    useEffect(() => {
        if (currentUser) {
            const firestore = getFirestore();
            const userDocRef = doc(firestore, 'users', currentUser.uid);
            getDoc(userDocRef).then(docSnap => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setUserRole(userData.role);
                } else {
                    console.error("No user document found");
                }
            }).catch(error => {
                console.error('Error fetching user role:', error);
            });
        }
    }, [currentUser, setUserRole]);

    const handleEnterClick = () => {
        navigate('/dashboard');
    };

    return (
        <div className="welcome-container-partial">
            <h1>Welcome to elevere!</h1>
            <p className='welcome-new-user-message-partial'>You've successfully verified your first invitation</p>
            <p className='welcome-new-user-message-partial'>Key in your second invitation code to unlock full access to elevere</p>
            <OnboardingSlideshowPartialUser />
            <button onClick={handleEnterClick} className="enter-app-btn-partial">Enter elevere</button>
        </div>
    );
};

export default WelcomeNewPartialUserPage;